
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import $ from 'jquery'
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const onChange = (e) => {
      console.log('onChange')

      store
        .dispatch(Actions.UPLOAD_PRICE_EXCEL, {
          file: e.target.files[0]
        })
        .then(() => {
          Swal.fire({
            text: "上传成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const onUpload = () => {
      console.log('onUpload')
      $("#btn_upload").click()
    }

    const goToPriceCheckDetail = (id) => {
      router.push({ name: "price_check_detail", params: {id: id} })
    }

    const price_check_records = ref([] as any)

    const query = () => {
      store
        .dispatch(Actions.GET_PRICE_CHECK_RECORDS)
        .then(() => {
          price_check_records.value = store.getters.currentPriceCheckRecords
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    query()

    return {
      onChange,
      onUpload,
      ApiService,
      price_check_records,
      goToPriceCheckDetail
    }
  }
})
